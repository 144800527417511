import { S3_PATH } from './Images';

export const noPackage = 'member';
export const advocate = 'advocate';
export const apprentice = 'apprentice';
export const promoter = 'promoter';
export const influencer = 'influencer';
export const ambassador = 'ambassador';

export const SORTED_PRODUCT_SIZES = ['SM', 'MD', 'LG', 'XL', 'XXL'];
export const PRODUCT_DEFAULT_PRICE = {};
export const PRODUCT_OVERRIDE_PRICE = {
	'TK-AC': { price: 'free', translate: true },
	'TK-AC24': { price: 'free', translate: true },
	AM: { price: '$59.95 - $69.95', translate: false },
	LIF: { price: '$89.95 - $179.95', translate: false },
	LMN: { price: '$44.95 - $109.95', translate: false },
	NBD: { price: '$44.95', translate: false },
	PM: { price: '$59.95 - $69.95', translate: false },
};

export const PRODUCT_OVERRIDE_EXCHANGE = {
	TW: {
		AM: { price: 'NT$1,858.45 - NT$2,168.45', translate: false },
		LIF: { price: 'NT$2,788.45 - NT$5,578.45', translate: false },
		LMN: { price: 'NT$1,393.45 - NT$3,408.45', translate: false },
		NBD: { price: 'NT$1,393.45 - NT$2,788.45', translate: false },
		PM: { price: 'NT$1,858.45 - NT$2,168.45', translate: false },
	},
};

export const PRODUCT_THEME = {
	default: {
		color: '#DF703D',
		hoverColor: 'rgba(200, 86, 33, 1)',
		focusColor: 'rgba(223, 112, 61, 0.5)',
	},
	bran: {
		color: '#DF703D',
		hoverColor: 'rgba(200, 86, 33, 1)',
		focusColor: 'rgba(223, 112, 61, 0.5)',
	},
	zlem: {
		color: '#65B0E6',
		hoverColor: 'rgba(86, 150, 200, 1)',
		focusColor: 'rgba(101, 176, 230, 0.5)',
	},
	uuth: {
		color: '#9FC642',
		hoverColor: 'rgba(130, 160, 50, 1)',
		focusColor: 'rgba(159, 198, 66, 0.5)',
	},
	plos: {
		color: '#e73340',
		hoverColor: 'rgba(200, 33, 40, 1)',
		focusColor: 'rgba(231, 51, 64, 0.5)',
	},
	byom: {
		color: '#784c99',
		hoverColor: 'rgba(100, 60, 130, 1)',
		focusColor: 'rgba(120, 76, 153, 0.5)',
	},
	tuun: {
		color: '#88939E',
		hoverColor: 'rgba(110, 120, 130, 1)',
		focusColor: 'rgba(136, 147, 158, 0.5)',
	},
	'naara-beauty-drink': {
		color: '#B00061',
		hoverColor: 'rgba(176, 0, 97, 1)',
		focusColor: 'rgba(184, 0, 101, 0.5)',
	},
};

export const PRODUCT_VOLUME = {};

export const SORTED_PRODUCT_SKUS = [
	'BR',
	'ZL',
	'UT',
	'PL',
	'BY',
	'LT',
	'CB-1001',
	'CB-1002',
	'CB-1003',
	'BR-1002',
	'BR-1003',
	'BR-1001',
	'BR-1006',
	'BR-1008',
	'BR-1010',
	'ZL-1001',
	'ZL-1007',
	'VR-1001',
	'PK-VIP',
	'LT-BR',
	'LT-ZL',
	'LT-BY',
	'AM-SCH',
	'AM-CPL',
	'PM-SCH',
	'PM-CPL',
	'RES-PKT',
	'LMN-NR',
	'LMN-SRM',
	'LMN-DM',
	'LMN-CLN',
	'MND-PKT',
	'RVB-PWD',
	'LIF-CQ8',
	'LIF-NMN',
	'FIN-CPL',
	'BAO-PWD',
	'NBD-SCH-1014',
	'NBD-PWD-1014',
];

export const GIFT_PRODUCTS_SORTED_SKU = [
	'BR',
	'ZL',
	'UT',
	'PL',
	'BY',
	'TN',
	'LT',
	'AM',
	'PM',
	'RES',
	'LMN',
	'MND',
	'RVB',
	'LIF',
	'FIN',
	'BAO',
	'NBD',
];

export const STORE_PRODUCT_SORT_ALL = [
	'BR',
	'ZL',
	'VR-1001',
	'UT',
	'PL',
	'BY',
	'LT',
	'AM',
	'PM',
	'RES',
	'LMN',
	'MND',
	'RVB',
	'LIF',
	'FIN',
	'BAO',
	'NBD',
];

export const STORE_PRODUCT_SORT_NOT_ALL = [
	'BR',
	'ZL',
	'UT',
	'PL',
	'BY',
	'VR-1001',
	'LT',
	'AM',
	'PM',
	'RES',
	'LMN',
	'MND',
	'RVB',
	'LIF',
	'FIN',
	'BAO',
	'NBD',
];

export const PRODUCT_NON_CHANGEABLE_QTYS = [
	'F-BH-BL',
	'F-BH-OR',
	'F-BO-0001',
	'F-BR-1001',
	'F-BR-1002',
	'F-BR-1003',
	'F-BR-1006',
	'F-BR-1008',
	'F-BR-1010',
	'F-CR-0001',
	'F-EP-BK',
	'F-FM-BK-MDLG',
	'F-FM-BK-SMMD',
	'F-ON-1001',
	'F-ON-1002',
	'F-ON-1002ES',
	'F-ON-1003',
	'F-ON-1003ES',
	'F-ON-1004',
	'F-PL-0001',
	'F-PL-0002',
	'F-PN-OR',
	'F-PS-BL-LG',
	'F-PS-BL-MD',
	'F-PS-BL-SM',
	'F-PS-BL-XL',
	'F-PS-BL-XXL',
	'F-PS-OR-LG',
	'F-PS-OR-MD',
	'F-PS-OR-SM',
	'F-PS-OR-XL',
	'F-PS-OR-XXL',
	'F-SB-OR',
	'F-SB-OR-SM',
	'F-SLB-BK',
	'F-VC-BR',
	'F-VC-CB',
	'F-VC-ZL',
	'F-VC-UT',
	'F-VC-PL',
	'F-VC-LV',
	'F-VP-BR',
	'F-VP-CB',
	'F-VP-ZL',
	'F-VP-UT',
	'F-VP-PL',
	'F-VP-LV',
	'F-WB-GR',
	'F-ZL-1001',
	'F-ZL-1007',
	'C-VP-BR',
	'C-VP-CB',
	'C-VP-ZL',
	'C-VP-UT',
	'C-VP-PL',
	'C-VP-LV',
	'F-VR-1001',
	'F-UT-1005',
	'F-PL-1009',
	'F-PL-1012',
	'F-BY-1011',
	'F-TN-RG',
	'F-TN-BS',
	'F-TN-SS',
	'F-TN-SD',
	'C-TN-SS',
	'C-TN-RG',
	'C-TN-BS',
	'C-TN-SD',
	'C-BH-BL',
	'C-BH-OR',
	'C-BN-BR',
	'C-BN-LR',
	'C-BN-UT',
	'C-BN-ZL',
	'C-EP-BK',
	'C-FM-BK-MDLG',
	'C-FM-BK-SMMD',
	'C-HS-BK-LG',
	'C-HS-BK-MD',
	'C-HS-BK-XL',
	'C-HS-BK-XS',
	'C-HS-BK-XXL',
	'C-PHC-BK',
	'C-PS-BL-LG',
	'C-PS-BL-MD',
	'C-PS-BL-SM',
	'C-PS-BL-XL',
	'C-PS-BL-XXL',
	'C-PS-OR-LG',
	'C-PS-OR-MD',
	'C-PS-OR-SM',
	'C-PS-OR-XL',
	'C-PS-OR-XXL',
	'C-SLB-BB',
	'C-SLB-BK',
	'C-WH-LI',
	'C-WH-WH',
	'C-WJ-GR-LG',
	'C-WJ-GR-MD',
	'C-WJ-GR-SM',
	'C-WJ-GR-XL',
	'C-WJ-GR-XXL',
	'C-VIP-1001',
	'C-VIP-1002',
	'C-VIP-1003',
	'C-VIP-1004',
	'C-ON-1002',
	'C-ON-1002ES',
	'C-VC-BR',
	'C-VC-PL',
	'C-VC-LV',
	'C-VC-UT',
	'C-VC-ZL',
	'C-ON-1004',
	'C-ON-1003',
	'C-BR-1001',
	'C-BR-1002',
	'C-BR-1003',
	'C-BR-1006',
	'C-BR-1008',
	'C-BR-1010',
	'C-G-BR-1001',
	'C-G-BR-1002',
	'C-G-BR-1003',
	'C-G-BR-1008',
	'C-G-BR-1010',
	'C-G-VR-1001',
	'C-G-ZL-1001',
	'C-G-ZL-1007',
	'C-G-ZL-T1004',
	'C-PL-1009',
	'C-BY-1011',
	'C-UT-1005',
	'C-VR-1001',
	'C-ZL-T1004',
	'C-ZL-1001',
	'C-ZL-1007',
	'C-G-BR-1006',
	'C-ON-1001',
	'C-PN-OR',
	'C-SB-OR',
	'C-SB-OR-SM',
	'C-WB-GR',
	'TK-VX22-SI-MP',
	'TK-VX22-SE-MP',
	'TK-VX22-CO-MP',
	'TK-VX22-CE-MP',
	'TK-VX22-SI-MP-2',
	'TK-VX22-SE-MP-2',
	'TK-VX22-CO-MP-2',
	'TK-VX22-CE-MP-2',
	'TK-VX22-SI-MP-3',
	'TK-VX22-SE-MP-3',
	'TK-VX22-CO-MP-3',
	'TK-VX22-CE-MP-3',
	'TK-VX22-SI-MP-4',
	'TK-VX22-SE-MP-4',
	'TK-VX22-CO-MP-4',
	'TK-VX22-CE-MP-4',
	'TK-VIT-SI-4N',
	'TK-VIT-SI-5N',
	'TK-VIT-SI-6N',
	'TK-VIT-CO-4N',
	'TK-VIT-CO-5N',
	'TK-VIT-CO-6N',
	'F-PK-VIP',
	'C-PK-VIP',
	'F-LT-BR',
	'C-LT-BR',
	'F-LT-ZL',
	'C-LT-ZL',
	'F-LT-BY',
	'C-LT-BY',
	'VST-10',
	'VST-1',
	'VST-13',
	'VST-19',
	'VST-53',
	'BO-0002',
	'F-BO-0002',
	'F-AM-SCH',
	'F-AM-CPL',
	'F-PM-SCH',
	'F-PM-CPL',
	'F-RES-PKT',
	'F-LMN-NR',
	'F-LMN-SRM',
	'F-LMN-DM',
	'F-LMN-CLN',
	'F-MND-PKT',
	'F-RVB-PWD',
	'F-LIF-CQ8',
	'F-LIF-NMN',
	'F-FIN-CPL',
	'F-BAO-PWD',
	'F-NBD-SCH-1014',
	'F-NBD-PWD-1014',
];

export const PRODUCT_DETAILS_LINKS = {
	BR: 'https://velovita.com/product/bran/',
	ZL: 'https://velovita.com/product/zlem/',
	UT: 'https://velovita.com/product/uuth/',
	PL: 'https://velovita.com/product/plos/',
	BY: 'https://velovita.com/product/byom/',
	'BR-1010': 'https://velovita.com/product/bran/',
	'BR-1002': 'https://velovita.com/product/bran/',
	'BR-1003': 'https://velovita.com/product/bran/',
	'BR-1001': 'https://velovita.com/product/bran/',
	'ZL-1001': 'https://velovita.com/product/zlem/',
	'VR-1001': 'https://velovita.com/product/bran/',
	'G-BR-1010': 'https://velovita.com/product/bran/',
	'G-BR-1002': 'https://velovita.com/product/bran/',
	'G-BR-1003': 'https://velovita.com/product/bran/',
	'G-BR-1001': 'https://velovita.com/product/bran/',
	'G-ZL-1001': 'https://velovita.com/product/zlem/',
	'G-VR-1001': 'https://velovita.com/product/bran/',
	'BR-1006': 'https://velovita.com/product/bran/',
	'G-BR-1006': 'https://velovita.com/product/bran/',
	'BR-1008': 'https://velovita.com/product/bran/',
	'G-BR-1008': 'https://velovita.com/product/bran/',
	'ZL-1007': 'https://velovita.com/product/zlem/',
	'G-ZL-1007': 'https://velovita.com/product/zlem/',
};

export const PRODUCT_MAX_QTY = {
	1003: 1,
	1004: 1,
};

export const PRODUCT_SKU_MAX_QTY = {
	// 'TK-AC24': 2,
	'BO-0002': 1,
};

export const PACKAGES = [
	noPackage,
	advocate,
	apprentice,
	promoter,
	influencer,
	ambassador,
];

export const DOUBLE_REFERRAL_BONUS_PACKAGES = {
	apprentice: { percentage: 10 },
	promoter: { percentage: 20 },
	influencer: { percentage: 30 },
	ambassador: { percentage: 40 },
};

export const PRODUCTS_LINKS = {
	BR: `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	ZL: `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	UT: `${S3_PATH}/assets/products/Velovita/info/uuth_brochure.pdf?v=2`,
	PL: `${S3_PATH}/assets/products/Velovita/info/plos_brochure.pdf?v=3`,
	TN: `${S3_PATH}/assets/products/Velovita/info/tuun_brochure.pdf?v=2`,
	BY: `${S3_PATH}/assets/products/Velovita/info/byom_brochure.pdf?v=1`,
	LT: `${S3_PATH}/assets/products/Velovita/info/lite_brochure.pdf?v=1`,
	'BR-1001': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'BR-1002': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'BR-1003': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'BR-1006': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'BR-1008': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'BR-1010': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'ZL-1001': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'VP-BR': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1001': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1002': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1003': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1006': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1008': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-BR-1010': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'G-ZL-1001': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'G-VR-1001': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'VP-ZL': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'VP-UT': `${S3_PATH}/assets/products/Velovita/info/uuth_brochure.pdf?v=2`,
	'VP-PL': `${S3_PATH}/assets/products/Velovita/info/plos_brochure.pdf?v=2`,
	'F-BR-1001': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-BR-1002': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-BR-1003': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-BR-1006': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-BR-1008': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-BR-1010': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-ZL-1001': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'F-VP-BR': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'F-VP-ZL': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'F-VP-UT': `${S3_PATH}/assets/products/Velovita/info/uuth_brochure.pdf?v=2`,
	'F-VP-PL': `${S3_PATH}/assets/products/Velovita/info/plos_brochure.pdf?v=2`,
	'C-VP-BR': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'C-VP-ZL': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'C-VP-UT': `${S3_PATH}/assets/products/Velovita/info/uuth_brochure.pdf?v=2`,
	'C-VP-PL': `${S3_PATH}/assets/products/Velovita/info/plos_brochure.pdf?v=2`,
	'VR-1001': `${S3_PATH}/assets/products/Velovita/info/bran_brochure.pdf?v=7`,
	'ZL-1007': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'F-ZL-1007': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'G-ZL-1007': `${S3_PATH}/assets/products/Velovita/info/zlem_brochure.pdf?v=4`,
	'LT-BR': `${S3_PATH}/assets/products/Velovita/info/lite_brochure.pdf?v=1`,
	'LT-ZL': `${S3_PATH}/assets/products/Velovita/info/lite_brochure.pdf?v=1`,
	'LT-BY': `${S3_PATH}/assets/products/Velovita/info/lite_brochure.pdf?v=1`,
};

export const PACKAGES_ACTIVATION_WEEKS = {
	default: {
		advocate: 0,
		apprentice: 0,
		promoter: 0,
		influencer: 0,
		ambassador: 0,
	},
};

export const FREEPRODUCTPREFIX = 'F-';

export const GRANDFATHERED_PREFIXES_RELATED_PRODUCT_MAP = {
	'G-BR': 'BR',
	'G-ZL': 'ZL',
	'G-VR-1001': 'VR-1001',
};

export const RELATED_PRODUCTS_BLACKLIST = [];

export const NON_AUTH_HIDDEN_PRODUCTS = [];

export const AGENCY_RELATED_PRODUCTS_BLACKLIST = ['VR-1001'];

export const BLACKLIST_NON_AUTOSELECTABLE = [
	'VP-BR',
	'VP-ZL',
	'VP-CB',
	'VP-UT',
	'VP-PL',
	'VP-LV',
	'BR-1001',
	'BR-1002',
	'BR-1006',
	'BR-1008',
	'BR-1010',
	'ZL-1001',
	'TK-VX-AI-EB',
	'TK-VX-GD-EB',
	'VR-1001',
	'UT-1005',
	'ZL-1007',
	'PL-1009',
	'BY-1011',
];

export const BACKOFFICE_ACCESS_SKU = 'BO-0001';
export const BACKOFFICE_UPGRADE_FEES = 'UF-0001';

export const SIMPLE_PRODUCTS_PARENT_PLACEHOLDER = 'none';

// Decoded name used in the url in product details page
export const ALLOWED_PRODUCTS_TO_REVIEW = [
	'Bran',
	'Zlem',
	'Uuth',
	'Plos',
	'Byom',
	'Tuun',
	'Bran-Variety-Pack',
	'Vip-Pack',
	'Lite',
	'Naara-Beauty-Drink',
];

export const AGENCY_PRODUCTS = {
	data: [
		{
			type: 'products',
			id: '58',
			attributes: {
				bo_id: 62,
				sku: 'ON',
				code_name: 'organic_networker',
				name: 'Organic Networker',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/organic_networker_book.png`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/organic_networker_book.png`,
				price: '$19.95 USD',
				price_amount: 19.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$19.95 USD',
				discount: [],
				extra_images: {
					ON: [
						{
							image: `${S3_PATH}/assets/products/Velovita/organic_networker_book.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/organic_networker_book.png`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Organic-Networker',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '68',
			attributes: {
				bo_id: 69,
				sku: 'VP',
				code_name: 'vpack',
				name: 'V-Pack',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/vpacks.png?v=2`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vpacks.png?v=2`,
				price: '$24.95 USD',
				price_amount: 24.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$24.95 USD',
				discount: [],
				extra_images: {
					VP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/vpacks.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vpacks.png?v=2`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'V-Pack',
				category_code: 'other',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		// {
		// 	type: 'products',
		// 	id: '122',
		// 	attributes: {
		// 		bo_id: 117,
		// 		sku: 'VR-1001',
		// 		code_name: 'variety_pack',
		// 		name: 'brān® Variety Pack',
		// 		has_configurations: false,
		// 		image: `${S3_PATH}/assets/products/Velovita/variety_pack.png`,
		// 		thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/variety_pack.png`,
		// 		price: '$104.95 USD',
		// 		price_amount: 104.95,
		// 		bvs: 47,
		// 		exchange: false,
		// 		exchange_price: '$104.95 USD',
		// 		discount: [],
		// 		extra_images: {
		// 			'VR-1001': [
		// 				{
		// 					image: `${S3_PATH}/assets/products/Velovita/variety_pack.png`,
		// 					thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/variety_pack.png`,
		// 				},
		// 			],
		// 		},
		// 		configurations: [],
		// 		children: [],
		// 		out_of_stock: false,
		// 		custom_shipping_date: false,
		// 		minimun_purchase_qty: {
		// 			restricted: false,
		// 			message_code_name: '',
		// 			min_qty: 0,
		// 		},
		// 		sold_out: false,
		// 		decoded_name: 'Bran-Variety-Pack',
		// 		category_code: 'main',
		// 		available_for_autoship: 1,
		// 		individual_purchase: false,
		// 		selectable: null,
		// 	},
		// },
		{
			type: 'products',
			id: '126',
			attributes: {
				bo_id: 119,
				sku: 'BR',
				code_name: 'bran',
				name: 'brān®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					BR: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png?v=1`,
						},
					],
					'BR-1001': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_lemon_drop_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lemon_drop_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_lemon_drop_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lemon_drop_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1002': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_caramel_macchiato_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_caramel_macchiato_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_caramel_macchiato_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_caramel_macchiato_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1003': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_chocolate_sea_salt_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_sea_salt_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_chocolate_sea_salt_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_sea_salt_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1008': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_chocolate_mint_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_mint_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_chocolate_mint_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_mint_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1010': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_peanut_butter_cup.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_peanut_butter_cup.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_peanut_butter_cup_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_peanut_butter_cup_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Bran',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '128',
			attributes: {
				bo_id: 120,
				sku: 'ZL',
				code_name: 'zlem',
				name: 'zlēm®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/zlem.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					ZL: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem.png?v=1`,
						},
					],
					'ZL-1001': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_red_velvet_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
					'ZL-T1004': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_red_velvet_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
					'ZL-1007': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_chocolate_strawberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_chocolate_strawberry_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_chocolate_strawberry_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Zlem',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '130',
			attributes: {
				bo_id: 123,
				sku: 'UT',
				code_name: 'uuth',
				name: 'uüth®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/uuth.png?v=2`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					UT: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth.png?v=2`,
						},
					],
					'UT-1005': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/uuth_superberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_superberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_superberry_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_superberry_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Uuth',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '132',
			attributes: {
				bo_id: 122,
				sku: 'CB',
				code_name: 'combo',
				name: 'Combo (brān® + zlēm®)',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/combo_chocolate.png?v=3`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/combo_chocolate.png?v=3`,
				price: '$149.95 USD',
				price_amount: 149.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$149.95 USD',
				discount: [],
				extra_images: {
					CB: [
						{
							image: `${S3_PATH}/assets/products/Velovita/combo_chocolate.png?v=3`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/combo_chocolate.png?v=3`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Combo',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: {
					products: {
						default: [
							'Bran',
							'Zlem',
							'Uuth',
							'Plos',
							'Byom',
						],
					},
					required_select: 2,
					variants: [
						{
							categories: {
								brans: 1,
								zlems: 1,
							},
							combination: [
								'Bran',
								'Zlem',
							],
							title: 'combo_bran_zlem_title',
							description: 'combo_bran_zlem_description',
						},
						{
							categories: {
								brans: 1,
								uuths: 1,
							},
							combination: [
								'Bran',
								'Uuth',
							],
							title: 'combo_bran_uuth_title',
							description: 'combo_bran_uuth_description',
						},
						{
							categories: {
								zlems: 1,
								uuths: 1,
							},
							combination: [
								'Zlem',
								'Uuth',
							],
							title: 'combo_zlem_uuth_title',
							description: 'combo_zlem_uuth_description',
						},
						{
							categories: {
								plos: 1,
								brans: 1,
							},
							combination: [
								'Plos',
								'Bran',
							],
							title: 'combo_plos_bran_title',
							description: 'combo_plos_bran_description',
						},
						{
							categories: {
								plos: 1,
								zlems: 1,
							},
							combination: [
								'Plos',
								'Zlem',
							],
							title: 'combo_plos_zlem_title',
							description: 'combo_plos_zlem_description',
						},
						{
							categories: {
								plos: 1,
								uuths: 1,
							},
							combination: [
								'Plos',
								'Uuth',
							],
							title: 'combo_plos_uuth_title',
							description: 'combo_plos_uuth_description',
						},
						{
							categories: {
								brans: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Byom',
							],
							title: 'combo_bran_byom_title',
							description: 'combo_description',
						},
						{
							categories: {
								zlems: 1,
								byoms: 1,
							},
							combination: [
								'Zlem',
								'Byom',
							],
							title: 'combo_zlem_byom_title',
							description: 'combo_description',
						},
						{
							categories: {
								uuths: 1,
								byoms: 1,
							},
							combination: [
								'Uuth',
								'Byom',
							],
							title: 'combo_uuth_byom_title',
							description: 'combo_description',
						},
						{
							categories: {
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Plos',
								'Byom',
							],
							title: 'combo_plos_byom_title',
							description: 'combo_description',
						},
					],
					discount_amount: 9.95,
					includes: [],
					show_if_free_products: true,
				},
			},
		},
		{
			type: 'products',
			id: '165',
			attributes: {
				bo_id: 188,
				sku: 'TP',
				code_name: 'tri_pack',
				name: 'products.tri_pack',
				has_configurations: false,
				image: `${S3_PATH}/assets/products/Velovita/tri-pack.png?v=2`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tri-pack.png?v=2`,
				price: '$249.90 USD',
				price_amount: 249.9,
				bvs: 137,
				exchange: false,
				exchange_price: '$249.90 USD',
				discount: [],
				extra_images: {
					TP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/tri-pack.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tri-pack.png?v=2`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Tri-Pack',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: {
					products: {
						default: [
							'Bran',
							'Zlem',
							'Uuth',
							'Plos',
							'Byom',
						],
					},
					required_select: 3,
					variants: [
						{
							categories: {
								brans: 1,
								zlems: 1,
								uuths: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Uuth',
							],
							title: 'tri_pack_bran_zlem_uuth_title',
							description: 'tri_pack_bran_zlem_uuth_description',
						},
						{
							categories: {
								brans: 1,
								zlems: 1,
								plos: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Plos',
							],
							title: 'tri_pack_bran_zlem_plos_title',
							description: 'tri_pack_bran_zlem_plos_description',
						},
						{
							categories: {
								brans: 1,
								uuths: 1,
								plos: 1,
							},
							combination: [
								'Bran',
								'Uuth',
								'Plos',
							],
							title: 'tri_pack_bran_uuth_plos_title',
							description: 'tri_pack_bran_uuth_plos_description',
						},
						{
							categories: {
								zlems: 1,
								uuths: 1,
								plos: 1,
							},
							combination: [
								'Zlem',
								'Uuth',
								'Plos',
							],
							title: 'tri_pack_zlem_uuth_plos_title',
							description: 'tri_pack_zlem_uuth_plos_description',
						},
						{
							categories: {
								brans: 1,
								zlems: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Byom',
							],
							title: 'tri_pack_bran_zlem_byom_title',
							description: 'tri_pack_description_2',
						},
						{
							categories: {
								zlems: 1,
								uuths: 1,
								byoms: 1,
							},
							combination: [
								'Zlem',
								'Uuth',
								'Byom',
							],
							title: 'tri_pack_zlem_uuth_byom_title',
							description: 'tri_pack_description_2',
						},
						{
							categories: {
								zlems: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Zlem',
								'Plos',
								'Byom',
							],
							title: 'tri_pack_zlem_plos_byom_title',
							description: 'tri_pack_description_2',
						},
						{
							categories: {
								brans: 1,
								uuths: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Uuth',
								'Byom',
							],
							title: 'tri_pack_bran_uuth_byom_title',
							description: 'tri_pack_description_2',
						},
						{
							categories: {
								brans: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Plos',
								'Byom',
							],
							title: 'tri_pack_bran_plos_byom_title',
							description: 'tri_pack_description_2',
						},
						{
							categories: {
								uuths: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Uuth',
								'Plos',
								'Byom',
							],
							title: 'tri_pack_uuth_plos_byom_title',
							description: 'tri_pack_description_2',
						},
					],
					discount_amount: 19.95,
					includes: [
						'combo_discount',
					],
					default_description: 'tri_pack_description_2',
					show_if_free_products: true,
				},
			},
		},
		{
			type: 'products',
			id: '202',
			attributes: {
				bo_id: 194,
				sku: 'PL',
				code_name: 'plos',
				name: 'plôs® THERMO',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/plos_mocha.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					PL: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha.png?v=1`,
						},
					],
					'PL-1012': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_mocha_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Plos',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '205',
			attributes: {
				bo_id: 197,
				sku: 'TPP',
				code_name: 'quad_pack',
				name: 'products.quad_pack',
				has_configurations: false,
				image: `${S3_PATH}/assets/products/Velovita/quad-pack.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/quad-pack.png?v=1`,
				price: '$339.85 USD',
				price_amount: 339.85,
				bvs: 187,
				exchange: false,
				exchange_price: '$339.85 USD',
				discount: [],
				extra_images: {
					TPP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/quad-pack.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/quad-pack.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Quad-Pack',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: {
					products: {
						default: [
							'Bran',
							'Zlem',
							'Uuth',
							'Plos',
							'Byom',
						],
					},
					required_select: 4,
					variants: [
						{
							categories: {
								brans: 1,
								zlems: 1,
								uuths: 1,
								plos: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Uuth',
								'Plos',
							],
							title: 'quad_pack_bran_zlem_uuth_plos_title',
							description: 'quad_pack_description',
						},
						{
							categories: {
								brans: 1,
								zlems: 1,
								uuths: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Uuth',
								'Byom',
							],
							title: 'quad_pack_bran_zlem_uuth_byom_title',
							description: 'quad_pack_description',
						},
						{
							categories: {
								brans: 1,
								zlems: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Plos',
								'Byom',
							],
							title: 'quad_pack_bran_zlem_plos_byom_title',
							description: 'quad_pack_description',
						},
						{
							categories: {
								zlems: 1,
								uuths: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Zlem',
								'Uuth',
								'Plos',
								'Byom',
							],
							title: 'quad_pack_zlem_uuth_plos_byom_title',
							description: 'quad_pack_description',
						},
						{
							categories: {
								brans: 1,
								uuths: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Uuth',
								'Plos',
								'Byom',
							],
							title: 'quad_pack_bran_uuth_plos_byom_title',
							description: 'quad_pack_description',
						},
					],
					discount_amount: 29.95,
					includes: [
						'triple_pack_discount',
					],
					show_if_free_products: true,
				},
			},
		},
		{
			type: 'products',
			id: '310',
			attributes: {
				bo_id: 301,
				sku: 'TN',
				code_name: 'tuun',
				name: 'tuün® RESONATE',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/tuun.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					TN: [
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun.png?v=1`,
						},
					],
					'TN-BS': [
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_black_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_black_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_description.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_description.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_info.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_info.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_how_to_use.png?v=1`,
						},
					],
					'TN-RG': [
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_rose_gold_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_rose_gold_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_description.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_description.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_info.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_info.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Tuun',
				category_code: 'wearables',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '375',
			attributes: {
				bo_id: 364,
				sku: 'QTP',
				code_name: 'quad_pack_tuun',
				name: 'products.quad_pack_tuun',
				has_configurations: false,
				image: `${S3_PATH}/assets/products/Velovita/quad-pack-tuun.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/quad-pack-tuun.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					QTP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/quad-pack-tuun.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/quad-pack-tuun.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Quad-Pack-Tuun',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '377',
			attributes: {
				bo_id: 371,
				sku: 'BY',
				code_name: 'byom',
				name: 'byōm®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/byom_strawberry.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					BY: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry.png?v=1`,
						},
					],
					'BY-1011': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_strawberry_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_how_to_use.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Byom',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '389',
			attributes: {
				bo_id: 383,
				sku: 'PTP',
				code_name: 'penta_pack',
				name: 'products.penta_pack',
				has_configurations: false,
				image: `${S3_PATH}/assets/products/Velovita/penta-pack.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/penta-pack.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					PTP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/penta-pack.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/penta-pack.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Penta-Pack',
				category_code: 'other',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: {
					products: {
						default: [
							'Bran',
							'Zlem',
							'Uuth',
							'Plos',
							'Byom',
						],
					},
					required_select: 5,
					variants: [
						{
							categories: {
								brans: 1,
								zlems: 1,
								uuths: 1,
								plos: 1,
								byoms: 1,
							},
							combination: [
								'Bran',
								'Zlem',
								'Uuth',
								'Plos',
								'Byom',
							],
							title: 'penta_pack',
							description: 'penta_pack_description',
						},
					],
					discount_amount: 39.95,
					includes: [
						'quad_pack_discount',
					],
					show_if_free_products: true,
				},
			},
		},
		{
			type: 'products',
			id: '406',
			attributes: {
				bo_id: 412,
				sku: 'LT',
				code_name: 'lite',
				name: 'Lite Versions',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/lite.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/lite.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					LT: [
						{
							image: `${S3_PATH}/assets/products/Velovita/lite.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/lite.png?v=1`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Lite',
				category_code: 'other',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
		{
			type: 'products',
			id: '396',
			attributes: {
				bo_id: 406,
				sku: 'PK-VIP',
				code_name: 'vip_pack',
				name: 'VIP Pack',
				has_configurations: false,
				image: `${S3_PATH}/assets/products/Velovita/vip_package_opened_box_2.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_opened_box_2.png?v=1`,
				price: '$149.95 USD',
				price_amount: 149.95,
				bvs: 60,
				exchange: false,
				exchange_price: '$149.95 USD',
				discount: [],
				extra_images: {
					PKVIP: [
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_opened_box_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_opened_box_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_opened_box_1.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_opened_box_1.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_opened_box_3.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_opened_box_3.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_closed_box_1.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_closed_box_1.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_opening_box_1.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_opening_box_1.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/vip_package_info_box.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/vip_package_info_box.png`,
						},
					],
				},
				configurations: [],
				children: [],
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Vip-Pack',
				category_code: 'other',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	],
	Bran: {
		data: {
			type: 'products',
			id: '126',
			attributes: {
				bo_id: 119,
				sku: 'BR',
				code_name: 'bran',
				name: 'brān®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					BR: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png?v=1`,
						},
					],
					'BR-1002': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1002.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1002.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_caramel_macchiato_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_caramel_macchiato_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_caramel_macchiato_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_caramel_macchiato_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1003': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1003.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1003.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_chocolate_sea_salt_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_sea_salt_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_chocolate_sea_salt_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_sea_salt_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1001': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_lemon_drop_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lemon_drop_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_lemon_drop_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lemon_drop_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1008': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1008.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1008.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_chocolate_mint_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_mint_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_chocolate_mint_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_mint_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
					'BR-1010': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/br1010.png`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1010.png`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_peanut_butter_cup_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_chocolate_mint_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/bran_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_how_to_use.png?v=1`,
						},
					],
				},
				configurations: {
					flavor: {
						1001: {
							code_name: 'lemon_drop',
							configurations: [],
						},
						1002: {
							code_name: 'caramel_macchiato',
							configurations: [],
						},
						1003: {
							code_name: 'chocolate_sea_salt',
							configurations: [],
						},
						1008: {
							code_name: 'chocolate_mint',
							configurations: [],
						},
						1010: {
							code_name: 'peanut_butter_cup',
							configurations: [],
						},
					},
				},
				children: {
					'BR-1002': {
						id: 11,
						code_name: 'BR1002',
						name: 'brān® - Caramel Macchiato',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/br1002.png`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1002.png`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'BR-1003': {
						id: 12,
						code_name: 'BR1003',
						name: 'brān® - Chocolate Sea Salt',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/br1003.png`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1003.png`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'BR-1001': {
						id: 10,
						code_name: 'BR1001',
						name: 'brān® - Lemon Drop',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/br1001.png`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1001.png`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'BR-1008': {
						id: 185,
						code_name: 'bran_chocolate_mint',
						name: 'brān® - Chocolate Mint',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/br1008.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1008.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'BR-1010': {
						id: 340,
						code_name: 'BR1010',
						name: 'brān® - Peanut Butter Cup',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/br1010.png`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/br1010.png`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Bran',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	Zlem: {
		data: {
			type: 'products',
			id: '128',
			attributes: {
				bo_id: 120,
				sku: 'ZL',
				code_name: 'zlem',
				name: 'zlēm®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/zlem.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					ZL: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem.png?v=1`,
						},
					],
					'ZL-T1004': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_red_velvet_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
					'ZL-1007': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zl1007.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zl1007.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_chocolate_strawberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_chocolate_strawberry_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_chocolate_strawberry_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_chocolate_strawberry_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
					'ZL-1001': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_red_velvet_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_1.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_1.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_red_velvet_supplement_facts_2.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_red_velvet_supplement_facts_2.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/zlem_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_how_to_use.png?v=1`,
						},
					],
				},
				configurations: {
					flavor: {
						1007: {
							code_name: 'chocolate_strawberry',
							configurations: [],
						},
						T1004: {
							code_name: 'red_velvet',
							configurations: [],
						},
					},
				},
				children: {
					'ZL-T1004': {
						id: 121,
						code_name: 'zlem_red_velvet',
						name: 'zlēm® - Red Velvet',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$89.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/zlem.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'ZL-1007': {
						id: 189,
						code_name: 'zlem_chocolate_strawberry',
						name: 'zlēm® - Chocolate Strawberry',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$89.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/zl1007.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zl1007.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Zlem',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	// 'Bran-Variety-Pack': {
	// 	data: {
	// 		type: 'products',
	// 		id: '122',
	// 		attributes: {
	// 			bo_id: 117,
	// 			sku: 'VR-1001',
	// 			code_name: 'variety_pack',
	// 			name: 'brān® Variety Pack',
	// 			has_configurations: false,
	// 			image: `${S3_PATH}/assets/products/Velovita/variety_pack.png`,
	// 			thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/variety_pack.png`,
	// 			price: '$104.95 USD',
	// 			price_amount: 104.95,
	// 			bvs: 47,
	// 			exchange: false,
	// 			exchange_price: '$104.95 USD',
	// 			discount: [],
	// 			extra_images: {
	// 				'VR-1001': [
	// 					{
	// 						image: `${S3_PATH}/assets/products/Velovita/variety_pack.png`,
	// 						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/variety_pack.png`,
	// 					},
	// 				],
	// 			},
	// 			configurations: [],
	// 			children: [],
	// 			out_of_stock: false,
	// 			custom_shipping_date: false,
	// 			minimun_purchase_qty: {
	// 				restricted: false,
	// 				message_code_name: '',
	// 				min_qty: 0,
	// 			},
	// 			sold_out: false,
	// 			decoded_name: 'Bran-Variety-Pack',
	// 			category_code: 'main',
	// 			available_for_autoship: 1,
	// 			individual_purchase: false,
	// 			selectable: null,
	// 		},
	// 	},
	// },
	Uuth: {
		data: {
			type: 'products',
			id: '130',
			attributes: {
				bo_id: 123,
				sku: 'UT',
				code_name: 'uuth',
				name: 'uüth®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/uuth.png?v=2`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					UT: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth.png?v=2`,
						},
					],
					'UT-1005': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth.png?v=2`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/uuth_superberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_superberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_superberry_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_superberry_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/uuth_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth_how_to_use.png?v=1`,
						},
					],
				},
				configurations: {
					flavor: {
						1005: {
							code_name: 'superberry',
							configurations: [],
						},
					},
				},
				children: {
					'UT-1005': {
						id: 124,
						code_name: 'uuth_superberry',
						name: 'uüth® - Superberry',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/uuth.png?v=2`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/uuth.png?v=2`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Uuth',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	Plos: {
		data: {
			type: 'products',
			id: '202',
			attributes: {
				bo_id: 194,
				sku: 'PL',
				code_name: 'plos',
				name: 'plôs® THERMO',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/plos_mocha.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					PL: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha.png?v=1`,
						},
					],
					'PL-1012': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_mocha_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/plos_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_how_to_use.png?v=1`,
						},
					],
				},
				configurations: {
					flavor: {
						1012: {
							code_name: 'mocha',
							configurations: [],
						},
					},
				},
				children: {
					'PL-1012': {
						id: 434,
						code_name: 'plos_mocha',
						name: 'plôs® THERMO - Mocha',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/plos_mocha.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/plos_mocha.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Plos',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	Byom: {
		data: {
			type: 'products',
			id: '377',
			attributes: {
				bo_id: 371,
				sku: 'BY',
				code_name: 'byom',
				name: 'byōm®',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/byom_strawberry.png?v=1`,
				price: '$99.95 USD',
				price_amount: 99.95,
				bvs: 0,
				exchange: false,
				exchange_price: '$99.95 USD',
				discount: [],
				extra_images: {
					BY: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry.png?v=1`,
						},
					],
					'BY-1011': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_stages.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_stages.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_strawberry_supplement_facts.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry_supplement_facts.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/byom_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_how_to_use.png?v=1`,
						},
					],
				},
				configurations: {
					flavor: {
						1011: {
							code_name: 'strawberry',
							configurations: [],
						},
					},
				},
				children: {
					'BY-1011': {
						id: 372,
						code_name: 'byom_strawberry',
						name: 'byōm® - Strawberry',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/byom_strawberry.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_strawberry.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Byom',
				category_code: 'main',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	Tuun: {
		data: {
			type: 'products',
			id: '310',
			attributes: {
				bo_id: 301,
				sku: 'TN',
				code_name: 'tuun',
				name: 'tuün® RESONATE',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/tuun.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					TN: [
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun.png?v=1`,
						},
					],
					'TN-RG': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/tuun_rose_gold.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_rose_gold.png?v=2`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_rose_gold_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_rose_gold_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_description.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_description.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_info.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_info.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_how_to_use.png?v=1`,
						},
					],
					'TN-BS': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/tuun_black_stainless_steel.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_black_stainless_steel.png?v=2`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_black_box.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_black_box.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_benefits.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_benefits.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_description.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_description.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_info.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_info.png?v=1`,
						},
						{
							image: `${S3_PATH}/assets/products/Velovita/tuun_how_to_use.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/tuun_how_to_use.png?v=1`,
						},
					],
					'TN-SD': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/tuun_swarovski_diamonds.png?v=2`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_swarovski_diamonds.png?v=2`,
						},
					],
				},
				configurations: {
					material_type: {
						RG: {
							code_name: 'rose_gold',
							configurations: [],
						},
						BS: {
							code_name: 'black_stainless_steel',
							configurations: [],
						},
						SD: {
							code_name: 'swarovski_diamonds',
							configurations: [],
						},
					},
				},
				children: {
					'TN-RG': {
						id: 304,
						code_name: 'tuun_rose_gold',
						name: 'tuün® RESONATE - Rose Gold',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: 125,
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/tuun_rose_gold.png?v=2`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_rose_gold.png?v=2`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'TN-BS': {
						id: 303,
						code_name: 'tuun_black_stainless_steel',
						name: 'tuün® RESONATE - Black',
						price: '$99.95 USD',
						price_amount: 99.95,
						bvs: 100,
						eb_price: null,
						exchange: false,
						exchange_price: '$99.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/tuun_black_stainless_steel.png?v=2`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_black_stainless_steel.png?v=2`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'TN-SD': {
						id: 425,
						code_name: 'tuun_swarovski_diamonds',
						name: 'tuün® RESONATE - Swarovski Diamonds',
						price: '$499.95 USD',
						price_amount: 499.95,
						bvs: 300,
						eb_price: null,
						exchange: false,
						exchange_price: '$499.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/tuun_swarovski_diamonds.png?v=2`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/agency/thumbnails/tuun_swarovski_diamonds.png?v=2`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Tuun',
				category_code: 'wearables',
				available_for_autoship: 0,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
	Lite: {
		data: {
			type: 'products',
			id: '406',
			attributes: {
				bo_id: 412,
				sku: 'LT',
				code_name: 'lite',
				name: 'Lite Versions',
				has_configurations: true,
				image: `${S3_PATH}/assets/products/Velovita/lite.png?v=1`,
				thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/lite.png?v=1`,
				price: '$0.00 USD',
				price_amount: 0,
				bvs: 0,
				exchange: false,
				exchange_price: '$0.00 USD',
				discount: [],
				extra_images: {
					LT: [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/lite.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/lite.png?v=1`,
						},
					],
					'LT-BR': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/bran_lite.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lite.png?v=1`,
						},
					],
					'LT-ZL': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/zlem_lite.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_lite.png?v=1`,
						},
					],
					'LT-BY': [
						{
							image: `${S3_PATH}/assets/products/Velovita/agency/byom_lite.png?v=1`,
							thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_lite.png?v=1`,
						},
					],
				},
				configurations: {
					presentations: {
						BR: {
							code_name: 'bran',
							configurations: [],
						},
						ZL: {
							code_name: 'zlem',
							configurations: [],
						},
						BY: {
							code_name: 'byom',
							configurations: [],
						},
					},
				},
				children: {
					'LT-BR': {
						id: 413,
						code_name: 'bran_lite',
						name: 'brān® Lite',
						price: '$49.95 USD',
						price_amount: 49.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$49.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/bran_lite.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/bran_lite.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'LT-ZL': {
						id: 417,
						code_name: 'zlem_lite',
						name: 'zlēm® Lite',
						price: '$49.95 USD',
						price_amount: 49.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$49.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/zlem_lite.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/zlem_lite.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
					'LT-BY': {
						id: 421,
						code_name: 'byom_lite',
						name: 'byōm® Lite',
						price: '$49.95 USD',
						price_amount: 49.95,
						bvs: '',
						eb_price: null,
						exchange: false,
						exchange_price: '$49.95 USD',
						image: `${S3_PATH}/assets/products/Velovita/agency/byom_lite.png?v=1`,
						thumbnail: `${S3_PATH}/assets/products/Velovita/thumbnails/byom_lite.png?v=1`,
						monthly_payment_info: [],
						out_of_stock: false,
						individual_purchase: false,
						custom_shipping_date: false,
						minimun_purchase_qty: {
							restricted: false,
							message_code_name: '',
							min_qty: 0,
						},
					},
				},
				out_of_stock: false,
				custom_shipping_date: false,
				minimun_purchase_qty: {
					restricted: false,
					message_code_name: '',
					min_qty: 0,
				},
				sold_out: false,
				decoded_name: 'Lite',
				category_code: 'other',
				available_for_autoship: 1,
				individual_purchase: false,
				selectable: null,
			},
		},
	},
};

export default {};
